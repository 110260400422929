import React from 'react';
import './AdventurePreviewButton.css';
import previewPhoneImage from '../../assets/preview-phone-small.1x.png'
import { Button } from '@mui/material';
import { IAdventure } from '../../interfaces/IAdventure';

export interface AdventurePreviewButtonProps {
  info: IAdventure;
  active: boolean;
  enabled?: boolean;
  enableHover?: boolean;
  onClick: () => void;
  onHover: () => void;
  onUnhover: () => void;
}

const AdventurePreviewButton: React.FC<AdventurePreviewButtonProps> = (props) => {
  return (
    <div
      onMouseOver={() => props.enableHover ? props?.onHover() : {}}
      onMouseOut={() => props.enableHover ? props?.onUnhover() : {}}
      className={`preview-border ${props.active && props.enabled ? 'preview-border-active' : ''}`}
    >
      <Button className={`preview-item ${props.active && props.enabled ? 'preview-active' : ''}`} onClick={props.onClick}>
          <div className='phone'>
            <img className='phone-image' src={previewPhoneImage} alt='phone' />
            <img className='phone-content' src={props.info.image} alt='preview' />
            <div className='phone-icon'>{props.info.icon}</div>
          </div>
          <span className='title'>{props.info.title ?? 'Default'}</span>
      </Button>
    </div>
  )
}

export default AdventurePreviewButton;