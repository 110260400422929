import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { logEvent } from "firebase/analytics";
import { track as trackAmplitude } from "@amplitude/analytics-browser";


const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyC8mueNQA97DGBCWxwwaND-NG9nAJe5Vlk",
  authDomain: "twitaar-ex.firebaseapp.com",
  databaseURL: "https://twitaar-ex.firebaseio.com",
  projectId: "twitaar-ex",
  storageBucket: "twitaar-ex.appspot.com",
  messagingSenderId: "953235401985",
  appId: "1:953235401985:web:7af65eb73d6908f9",
  measurementId: "G-3QN40576FG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const logFirebaseEvent = (name: string) => {
  logEvent(analytics, name);
  trackAmplitude(name);
};

export { app, auth, analytics, logFirebaseEvent };