import React, { useEffect, useState } from 'react';
import './UserPanel.css';
import NoAdventuresImage from '../../assets/content/no-adventures.svg';
import { IAdventureChat } from '../../interfaces/IChatCollection';

export interface UserPanelProps {
  opened?: boolean;
}

const UserPanel: React.FC<UserPanelProps> = (props) => {

  const [chats, setChats] = useState<Array<IAdventureChat>>([]);

  useEffect(() => {
    setChats([]);
  }, []);

  const renderChats = () => {
    return (
      <div>
        {chats.map((chat) => chat.title)}
      </div>
    )
  }

  const renderNoChats = () => {
    return (
      <div className='user-panel-no-chats'>
        <img src={NoAdventuresImage} alt='No chats' />
        <br />
        You have no created adventures yet.
      </div>
    )
  }

  return (
    <div className={`${props.opened? 'user-panel-open' : 'user-panel-closed'}`}>
      {props.opened && (
        <div className='user-panel-container'>
          <div className='user-panel-content'>
          My adventures
            {chats.length === 0 ? renderNoChats() : renderChats()}
          </div>
        </div>)}
    </div>
  )
}

export default UserPanel;