import React from 'react';
import './App.css';
import logo from './assets/logo.min.svg';
import aiIcon from './assets/ai-icon.min.svg';
import burgerMenuicon from './assets/icons/burger-menu.svg';
import ChatApp from './ChatApp';
import { ProfileBlock } from './components/ProfileBlock/ProfileBlock';
import { logFirebaseEvent } from './firebase/firebase';
import CookiesWindow from './components/CookiesWindow/CookiesWindow';
import { IconButton } from '@mui/material';
import { useAuth } from './contexts/authContext';

const allowUserPanel = false; /// it's not finished so its disabled temporarily

function App() {

    const [menuOpen, setMenuOpen] = React.useState(false);

    const { userLoggedIn } = useAuth();

    const handleLogoClick = () => {
        logFirebaseEvent('webct_logo_clicked');
    }

    return (
        <div className="App" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <nav className='App-header'>
                <div className='app-header-left'>
                    {userLoggedIn && allowUserPanel ? (<IconButton onClick={() => setMenuOpen(!menuOpen)}>
                        <img src={burgerMenuicon} alt='menu' />
                    </IconButton>) : null}
                    <img src={logo} alt='XSight logo' onClick={handleLogoClick} />
                    Adventure Creator
                    <img src={aiIcon} alt='ai' />
                </div>
                <ProfileBlock />
            </nav>
            <ChatApp userPanelOpened={menuOpen} />
            <CookiesWindow />
        </div>
    );
}

export default App;
