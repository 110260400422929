import React, { useState } from 'react';
import './LoginPopup.css';
import ModalWindow from '../ModalWindow/ModalWindow';
import {
  doCreateuserWithEmailAndPassword,
  doSignInWithApple,
  doSignInWithEmailAndPassword,
  doSignInWithGoogle
} from '../../firebase/auth';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import { ReactComponent as PasswordIcon } from '../../assets/icons/password.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import XsightLogo from '../../assets/icons/login-xsight-logo.svg';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FirebaseError } from 'firebase/app';
import { useSnackbar } from 'notistack';
import { analytics } from '../../firebase/firebase';
import { logEvent } from 'firebase/analytics';

interface ILoginPopupProps {
  open: boolean;
  onClose: () => void;
}

interface ILoginFormInput {
  email: string;
  password: string;
}

export const LoginPopup = (props: ILoginPopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm<ILoginFormInput>();

  const handleLoginWighGoogle = async () => {
    const result = await doSignInWithGoogle();
    if (result) {
      // TODO: handle result
    }
  };

  const handleLoginWithApple = async () => {
    const result = await doSignInWithApple();
    if (result) {
      // TODO: handle result
    }
  };

  const toggleCreatingAccount = () => {
    logEvent(analytics, 'webct_auth_clicked');
    setIsCreatingAccount(!isCreatingAccount);
  };

  const handleLogin = async (email: string, password: string) => {
    if (!isSigningIn) {
      setIsSigningIn(true);
      try {
        await doSignInWithEmailAndPassword(email, password);
        logEvent(analytics, 'webct_log_in_successfull');
      } catch (error: any) {
        const firebaseError = error as FirebaseError;
        if (!firebaseError) {
          return;
        }
        logEvent(analytics, 'webct_log_in_failed');
        enqueueSnackbar(firebaseError.message, {  variant: 'error' });
      }
    }
    setIsSigningIn(false);
  };

  const handleSignUp = async (email: string, password: string) => {
    if (!isSigningIn) {
      setIsSigningIn(true);
      try {
        await doCreateuserWithEmailAndPassword(email, password);
        logEvent(analytics, 'webct_sign_up_successfull');
      } catch (error) {
        const firebaseError = error as FirebaseError;
        if (!firebaseError) {
          return;
        }
        logEvent(analytics, 'webct_sign_up_failed');
        enqueueSnackbar(firebaseError.message, {  variant: 'error' });
      }
    }
    setIsSigningIn(false);
  } 
  
  const onSubmit: SubmitHandler<ILoginFormInput> = (data) => {
    if (isCreatingAccount) {
      handleSignUp(data.email, data.password);
    } else {
      handleLogin(data.email, data.password);
    }
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const signInButtonText = () => (
    <>
      Already have an account? &nbsp;
      <span className="login-window-login-text">Log in</span>
    </>
  );
  const signUpButtonnText = () => (
    <>
      Don&apos;t have an account? &nbsp;
      <span className="login-window-login-text">Sign up</span>
    </>
  );

  return (
    <ModalWindow
      styles={{
        maxWidth: 740,
        width: '80%',
        maxHeight: 560,
        height: '90%'
      }}
      isOpen={props.open}
      toggleModal={props.onClose}
      header={<img src={XsightLogo} alt="XSight Logo" className="login-window-logo" />}
      withClose>
      <div className="login-window">
        <form
          className="login-window-content"
          onSubmit={handleSubmit(onSubmit)}>
          <span className="login-title">
            {isCreatingAccount
              ? 'Create an account or log in if you already have one'
              : 'Welcome back'}
          </span>
          <Button
            onClick={handleLoginWithApple}
            className="login-window-button login-window-button-apple">
            {isCreatingAccount ? 'Continue with' : 'Log in with'}&nbsp;<strong>Apple</strong>
          </Button>
          <Button
            onClick={handleLoginWighGoogle}
            className="login-window-button login-window-button-google">
            {isCreatingAccount ? 'Continue with' : 'Log in with'}&nbsp;<strong>Google</strong>
          </Button>
          <span>or</span>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              )
            }}
            {...register('email', { required: 'Please enter a valid email address' })}
            error={!!errors.email}
            className="login-window-input"
            type="email"
            placeholder="Enter email address"
            variant="outlined"
          />
          {errors.email && <span className="login-window-error-message">{errors.email.message}</span>}
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PasswordIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end">
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={!!errors.password}
            {...register('password', { required: 'Password is required' })}
            aria-invalid={errors.email ? "true" : "false"}
            className="login-window-input"
            type={showPassword ? 'text' : 'password'}
            placeholder="Create password"
            variant="outlined"
          />
          {errors.password && <span className="login-window-error-message">{errors.password.message}</span>}
          <Button
            type="submit"
            className="login-window-button login-window-button-login">
            Continue with Email
          </Button>
          <Button
            className="login-window-button"
            onClick={toggleCreatingAccount}>
            {isCreatingAccount ? signInButtonText() : signUpButtonnText()}
          </Button>
          <span className="login-window-tou-text">
            By creating an account, you agree to XSight{' '}
            <a className="login-window-tou-url" href="#">
              Terms of Use, Privacy Policy
            </a>
          </span>
        </form>
      </div>
    </ModalWindow>
  );
};
