import './TextButton.css';
import React, { ReactNode } from 'react';

interface TextButtonProps {
  children?: ReactNode;
  onClick: () => void;
}

const TextButton: React.FC<TextButtonProps> = (props) => {
  return (<button className='text-button-base' type='button' onClick={props.onClick}>{props.children}</button>)
}

export default TextButton;