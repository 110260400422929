import React, { ReactNode, useEffect, useState } from 'react';
import './AdventurePreview.css';
import { IAdventure } from '../../interfaces/IAdventure';
import { IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as GameplayIcon } from '../../assets/icons/ar-gameplay.svg';
import { ReactComponent as Assets3DIcon } from '../../assets/icons/assets-3d.svg';
import { ReactComponent as Assets2DIcon } from '../../assets/icons/assets-2d.svg';
import { ReactComponent as ClickTouchIcon } from '../../assets/icons/click-touch.svg';
import { IXSCollectibleConfig } from '../../interfaces/IXSightMessage';
import { logFirebaseEvent } from '../../firebase/firebase';

export interface AdventurePreviewProps {
  info: IAdventure;
  children?: ReactNode;
  collectibleConfig?: IXSCollectibleConfig;
  onClose: any;
}

enum TAB_TYPE {
  'gameplay',
  'assets3d',
  'assets2d'
}

const AdventurePreview: React.FC<AdventurePreviewProps> = (props) => {
  const [tab, selectTab] = useState<TAB_TYPE>(TAB_TYPE.gameplay);
  const [contentClass, setContentClass] = useState<string>('adventure-preview-gameplay');
  
  const handleTabChange = (event: any, tab: TAB_TYPE) => {
    if (tab !== null) {
      selectTab(tab);
    }
  };

  useEffect(() => {
    logFirebaseEvent('webct_adventure_preview_tab_changed');
    switch (tab) {
      case TAB_TYPE.gameplay:
        setContentClass('adventure-preview-gameplay');
        break;
      default:
        setContentClass('adventure-preview-gameplay');
        break;
    }
  }, [tab]);

  const renderDescription = () => {
    switch (tab) {
      case TAB_TYPE.assets2d:
        return (<div className='adventure-preview-description'><ClickTouchIcon />Click 2d icons to view details </div>);
      case TAB_TYPE.assets3d:
        return (<div className='adventure-preview-description'><ClickTouchIcon /> Click 3d models and map to view details</div>);
      case TAB_TYPE.gameplay:
        return (<div className='adventure-preview-description'>This is a preview of how your adventure will look like</div>);
    }
  }

  return (
    <div className='adventure-preview-content'>
      <div className='adventure-preview-subheader'>
        <span>Adventure preview</span>
        <IconButton
          className='adventure-preview-close-button'
          onClick={props.onClose}
        ><CloseIcon /></IconButton>
      </div>
      <ToggleButtonGroup
        value={tab}
        exclusive
        onChange={handleTabChange}
        aria-label="Tabs view"
        className='adventure-preview-tab-list'
      >
        <ToggleButton className='adventure-preview-tab' value={TAB_TYPE.gameplay} aria-label="AR Gameplay">
          <GameplayIcon />
          <span>AR Gameplay</span>
        </ToggleButton>
        <ToggleButton className='adventure-preview-tab' value={TAB_TYPE.assets3d} aria-label="3D Assets">
          <Assets3DIcon />
          <span>3D Assets</span>
        </ToggleButton>
        <ToggleButton className='adventure-preview-tab' value={TAB_TYPE.assets2d} aria-label="2D Assets">
          <Assets2DIcon />
          <span>2D Assets</span>
        </ToggleButton>
      </ToggleButtonGroup>
      <div className={contentClass}>
        {props.children}
      </div>
      {renderDescription()}
    </div>
  )
}

export default AdventurePreview;