import React, { ReactNode } from 'react';
import { Avatar, Button, IconButton } from '@mui/material';
import AiAssistantAvatar from '../../assets/ai-userpic.png';
import './ChatMessage.css';
import { ReactComponent as RegenerateIcon } from '../../assets/icons/regenerate.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as PreviewIcon } from '../../assets/icons/adventure-preview.svg';
import { ReactComponent as PublishIcon } from '../../assets/icons/publish.svg';
import { logFirebaseEvent } from '../../firebase/firebase';

interface ChatMessageProps {
  isUser: boolean;
  children?: string | ReactNode;
  avatarUrl?: string;
  editable?: boolean;
  isPublish?: boolean;
  isPreview?: boolean;
  onPreview?: () => void;
  onPublish?: () => void;
  isTitle?: boolean;
  isLast?: boolean;
}

const ChatMessage: React.FC<ChatMessageProps> = (props) => {
  const avatarUrl = props.isUser ? props.avatarUrl : AiAssistantAvatar;
  const useAdditionalBlock = props.isPreview || props.isPublish || props.editable;

  const handleAvatarClicked = (isUser: boolean) => {
    logFirebaseEvent(isUser ? 'webct_user_avatar_clicked' : 'webct_ai_avatar_clicked');
  }

  const handleEditClicked = () => {
    logFirebaseEvent('webct_message_action_edit_clicked');
  }

  const handleRegenerateClicked = () => {
    logFirebaseEvent('webct_message_action_regenerate_clicked');
  }

  const handlePublishClicked = () => {
    logFirebaseEvent('webct_publish_adventure_clicked');
    if (props.onPublish) props.onPublish();
  }

  return (
    <div style={{
      marginBottom: props.isLast ? 30 : 0,
    }}>
      <div
        className={props.isUser ? 'message-question' : 'message-answer'}
      >
        <Avatar
          onClick={() => handleAvatarClicked(props.isUser)}
          sx={{ width: 32, height: 32 }}
          alt={`${props.isUser ? 'User' : 'Ai Assistant'} Avatar`}
          src={avatarUrl}>
          {/* {props.avatarUrl ? null : } */}
        </Avatar>
        <div
          className={props.isUser ? 'bubble-question' : 'bubble-answer'}
          style={props.isTitle ? { marginRight: '0px' } : {}}
        >
          <div className={props.isUser ? '' : 'bubble-answer-message'}>
            {props.children}
          </div>
        </div>
      </div>
      {useAdditionalBlock && (<div className='bubble-additional-block'>
        <div className='message-answer'>
          {props.editable && <div>
            <IconButton onClick={handleRegenerateClicked}><RegenerateIcon /></IconButton>
            <IconButton onClick={handleEditClicked}><EditIcon /></IconButton>
          </div>}
        </div>
        {(props.isPreview || props.isPublish)
          && (<div
              style={{
                display: 'flex',
              }}>
          {props.isPreview && <Button onClick={props.onPreview} className='bubble-adventure-preview-button'><PreviewIcon /> Adventure Preview</Button>}
          {props.isPublish && <Button onClick={handlePublishClicked} className='bubble-create-adventure-button'><PublishIcon /> Publish Adventure</Button>}
        </div>)}
      </div>)}
    </div>
  )
}

export default ChatMessage;