import { IconButton, Snackbar } from '@mui/material';
import './PublishAdventureWindow.css';
import React, { useState } from 'react';
import TextButton from '../TextButton/TextButton';
import { logFirebaseEvent } from '../../firebase/firebase';

interface PublishAdventureButtonProps {
  icon: any;
  title: string;
  url: string;
}

const PublishAdventureButton: React.FC<PublishAdventureButtonProps> = (props) => {
  const [snackbarOpened, setSnackbarOpened] = useState<boolean>(false);
  const copyUrl = () => {
    setSnackbarOpened(true);
    logFirebaseEvent('webct_adventure_published_dialog_share_clicked');
    navigator.clipboard.writeText(props.url);
  };

  return (<div className='publish-adventure-button'>
    <IconButton onClick={copyUrl} className='publish-adventure-icon-button' style={{ width: 56, height: 56 }}>{props.icon}</IconButton>
    <TextButton onClick={copyUrl}>{props.title}</TextButton>
    <Snackbar
      open={snackbarOpened}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={() => setSnackbarOpened(false)}
      message="Url copied to your clipboard"
      key={`${props.title}-button`}
      autoHideDuration={1800}
    />
  </div>)
}

export default PublishAdventureButton;