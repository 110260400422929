export const sleep = (ms: number) => new Promise<void>((resolve) => {
  setTimeout(resolve, ms);
});

export const logError = (...params: any) => {
  // eslint-disable-next-line no-console
  console.error(...params);
}

export const logInfo = (...params: any) => {
  // eslint-disable-next-line no-console
  console.log(...params); 
}
