import React, { CSSProperties, ReactNode } from 'react';
import './ModalWindow.css';
import { IconButton, Modal } from '@mui/material';
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

interface ModalWindowProps {
  isOpen: boolean;
  withExitOnEscape?: boolean;
  children?: ReactNode;
  className?: string;
  styles?: CSSProperties;
  toggleModal: (value: boolean) => void;
  disableBackdropClick?: boolean;
  withClose?: boolean;
  header?: ReactNode;
}

const ModalWindow: React.FC<ModalWindowProps> = (props) => {
  const { toggleModal, isOpen, children, styles: style } = props;

  function handleClose(event: any, reason: 'escapeKeyDown' | 'backdropClick'): void {
    if (reason === 'backdropClick' && props.disableBackdropClick) return;
    toggleModal(false)
  }

  return <Modal className={`publish-modal-paddings ${props.className || ''}`} open={isOpen} onClose={handleClose}>
    <div className='publish-modal-window' style={style || {}}>
      {
        props.withClose && ( 
          <div className='modal-window-top'>
            <div style={{ width: 32 }} />
            <div>{props.header}</div>
            <IconButton onClick={() => props.toggleModal(false)}><CloseIcon /></IconButton>
          </div>
      )}
      {children}
    </div>
  </Modal>
}

export default ModalWindow;