import { auth } from './firebase';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup,  } from 'firebase/auth';
import { OAuthProvider } from "firebase/auth";

export const doCreateuserWithEmailAndPassword = async (email: string, password: string) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const doSignInWithEmailAndPassword = async (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const doSignInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  // result.user 
  return result;
};

export const doSignInWithApple = async () => {
  const provider = new OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');
  try {
    const result = await signInWithPopup(auth, provider);
    // result.user 
    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error', error);
  }
};

export const doSignOut = async () => {
  return auth.signOut();
};

// export const doPasswordReset = async (email: string) => {
//   return auth.sendPasswordResetEmail(email);
// };
