export interface IUnityGameConfig {
  loaderUrl: string;
  dataUrl: string;
  frameworkUrl: string;
  codeUrl: string;
  streamingAssetsUrl?: string;
  companyName: string;
  productName: string;
  productVersion: string;
}

export const unityGamePaths: IUnityGameConfig = {
  loaderUrl: "https://storage.googleapis.com/cloud_adventures_web_content/webgl-fallback/Build/public.loader.js",
  dataUrl: "https://storage.googleapis.com/cloud_adventures_web_content/webgl-fallback/Build/public.data.unityweb",
  frameworkUrl: "https://storage.googleapis.com/cloud_adventures_web_content/webgl-fallback/Build/public.framework.js.unityweb",
  codeUrl: "https://storage.googleapis.com/cloud_adventures_web_content/webgl-fallback/Build/public.wasm.unityweb",
  streamingAssetsUrl: "https://storage.googleapis.com/cloud_adventures_web_content/webgl-fallback/StreamingAssets",
  companyName: 'XSight',
  productName: 'Adventure Creator Tool',
  productVersion: '0.1',
}
