import React, { useEffect, useState } from 'react';
import './ProfileBlock.css';
import { Avatar, Button, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { ReactComponent as SignInUpIcon } from '../../assets/icons/sign-in-up.svg';
import { LoginPopup } from '../LoginPopup/LoginPopup';
import { useAuth } from '../../contexts/authContext';
import { doSignOut } from '../../firebase/auth';

export const ProfileBlock = () => {
  const [popupOpened, setPopupOpened] = useState(false);
  const { userLoggedIn, currentUser } = useAuth();
  const [openMenu, setOpenMenu] = useState(false);
  const menuRef = React.useRef<HTMLButtonElement>(null);

  const handleSignInUp = () => {
    setPopupOpened(true);
  };

  useEffect(() => {
    if (userLoggedIn) {
      setPopupOpened(false);
    }
  }, [userLoggedIn]);

  function handleCloseMenu(): void {
    setOpenMenu(false);
  }

  const handleLogout = () => {
    setOpenMenu(false);
    doSignOut();
  }

  return (
    <>
      <div>
        {
          userLoggedIn
            ? <IconButton ref={menuRef} onClick={() => setOpenMenu(!openMenu)}>
                <Avatar>{currentUser?.email?.substring(0, 1)}</Avatar>
              </IconButton>
            : <Button className='sign-in-up-button' variant='contained' onClick={handleSignInUp}>
                <SignInUpIcon />
                Sign Up
              </Button>
        }
        <Popper
          open={openMenu}
          anchorEl={menuRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseMenu}>
                  <MenuList
                    autoFocusItem={openMenu}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    // onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <LoginPopup open={popupOpened} onClose={() => setPopupOpened(false)} />
      </div>
    </>
  )
};