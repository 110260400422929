import React, { useCallback, useRef, useState } from 'react';
import './main.css';
import aiUserPic from './assets/ai-userpic.png';
import ChatMessage from './components/ChatMessage/ChatMessage';
import AdventurePreviewButton from './components/AdventurePreviewButton/AdventurePreviewButton';
import previewPhoneImage from './assets/preview-phone-big.svg';
import { ReactComponent as CreateFromScratchIcon } from './assets/icons/create-from-scratch.svg';
import { IAdventure } from './interfaces/IAdventure';
import { Button } from '@mui/material';
import PreviewVideoPlayer from './components/PreviewVideoPlayer/PreviewVideoPlayer';
import { analytics } from './firebase/firebase';
import { logEvent } from 'firebase/analytics';
import { AdventureTypes } from './utils/constants';

interface MainPageProps {
  adventures: Array<IAdventure>;
  onSelectAdventure: (adventure: IAdventure) => void;
  onHoverAdventure: (adventure: IAdventure) => void;
  onUnhoverAdventure: () => void;
  onVideosLoaded: (value: boolean) => void;
}

const emptyAdventure: IAdventure = {
  icon: null,
  id: AdventureTypes.CUSTOM,
  image: '',
  previewUrl: '',
  title: '',
  prompt: '',
}

const ratio = 610 / 281; // h / w

const MainPage: React.FC<MainPageProps> = (props) => {
  const { adventures, onHoverAdventure, onUnhoverAdventure, onVideosLoaded } = props;
  const [currentAdventure, setCurrentAdventure] = useState<IAdventure>(adventures[0]);
  const [previewingAdventure, setPreviewingAdventure] = useState<IAdventure | null>(null);
  const [previewWidth, setPreviewWidth] = useState<number>(1);
  const [previewHeight, setPreviewHeight] = useState<number>(1 * ratio);
  const urls: Array<string> = props.adventures.map((item) => item.previewUrl);
  const previewPanelRef = useRef<HTMLDivElement>(null);

  const setAdventure = (adventure: IAdventure) => {
    const eventId = `web_select_adventure_preview_${adventure.id}`;
    logEvent(analytics, eventId);
    setCurrentAdventure(adventure);
    props.onSelectAdventure(adventure);
  };

  const previewImage = currentAdventure?.id === AdventureTypes.CUSTOM ? '/images/default-state-preview.min.svg' : previewPhoneImage;

  const handleHoverAdventure = (adv: IAdventure): void => {
    setPreviewingAdventure(adv);
    onHoverAdventure(adv);
  }

  const handleUnhoverAdventure = (): void => {
    setPreviewingAdventure(null);
    onUnhoverAdventure();
  }

  const handleResize = useCallback(() => {
    const previewPanel: HTMLElement | null = document.getElementById('main-content-preview-id');
    if (!previewPanel) return;
    let w = previewPanel.clientWidth;
    let h = w * ratio;
    const maxH = window.innerHeight - 250; // paddings from top and bottom blocks (hardcoded)
    if (h > maxH) {
      h = maxH > 200 ? maxH : 200;
      w = h / ratio;
    }
    if (w === previewWidth) return;
    setPreviewWidth(parseInt(w.toString()));
    setPreviewHeight(parseInt(h.toString()));
  }, [setPreviewWidth, setPreviewHeight, previewWidth]);

  const observerCallback: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
    window.requestAnimationFrame((): void | undefined => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      handleResize();
    });
  };

  // Attach resize event listener when the component mounts
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    
    const observer = new ResizeObserver(observerCallback);

    if (previewPanelRef.current) observer.observe(previewPanelRef.current);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, [handleResize]);

  return (<div className='main-page'>
    <div className='main-content-controls'>
      <header className='main-header'>
        Create your own AR Adventure with Ai assistant
      </header>
      <ChatMessage isUser={false} avatarUrl={aiUserPic} isTitle>
        <span className='subheader'>👋 Hey there, welcome to XSight Creator Tool!</span>
        <span>I&apos;m your trusty personal assistant, and I&apos;m here to help you bring your AR adventure to life. Feel free to type your adventure details you&apos;d like to create, or you can remix one of the 3 exciting adventures below 👇</span>
      </ChatMessage>
      <div className='previews-list'>
        {
          adventures.map((item) => 
            <AdventurePreviewButton
              key={`adventure-${item.id}`}
              info={item}
              enabled={true}
              active={currentAdventure.id === item.id}
              onClick={() => setAdventure(item)}
              onHover={() => handleHoverAdventure(item)}
              onUnhover={handleUnhoverAdventure}
            />)
        }
      </div>
      <div className='create-button-block'>
        <Button
          className='create-from-scratch-button'
          variant='text'
          style={{
            marginLeft: 40,
            width: '100%',
          }}
          onClick={() => setAdventure(emptyAdventure)}
        >
          <CreateFromScratchIcon />
          Create from scratch
        </Button>
      </div>
    </div>
    <div className='main-content-preview' id='main-content-preview-id' ref={previewPanelRef} >
        <img
          className='main-content-preview-background'
          src={previewImage}
          alt='phone background'
          style={{
            width: previewWidth,
            height: previewHeight * 0.94,
          }}
        />
        <PreviewVideoPlayer
          onVideoLoaded={onVideosLoaded}
          videoUrls={urls}
          width={previewWidth}
          height={previewHeight}
          currentUrl={previewingAdventure?.previewUrl || currentAdventure.previewUrl}
        />
    </div>
  </div>)
}

export default MainPage;
