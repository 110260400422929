import React, { useEffect, useRef, useState } from 'react';
import './UnityGame.css';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { unityGamePaths } from '../../utils/basicConfig';
import { CircularProgress } from '@mui/material';
import previewPhoneImage from '../../assets/preview-phone-big.svg';
import previewOverlay from '../../assets/unity-game-overlay.svg';
import previewOverlayText from '../../assets/unity-game-overlay-text.svg';
import { useDivResize } from '../../hooks/useDivResize';
import { logFirebaseEvent } from '../../firebase/firebase';

export interface UnityGameProps {
  collectableModel?: string | null;
  npcModel?: string | null;
  enabled?: boolean;
  opened?: boolean;
}

const ratio: number = 610 / 281;

const UnityGame: React.FC<UnityGameProps> = (props) => {
  const { collectableModel, npcModel } = props;
  const { unityProvider, sendMessage, isLoaded, loadingProgression } = useUnityContext(unityGamePaths);
  const [previewWidth, setPreviewWidth] = useState<number>(281);
  const [previewHeight, setPreviewHeight] = useState<number>(610);
  const divRef = useRef<HTMLDivElement>(null);
  const [isLocked, setIsLocked] = useState<boolean>(false);

  const { handleResize } = useDivResize({
    divRef,
    width: previewWidth,
    setWidth: setPreviewWidth,
    setHeight: setPreviewHeight,
    minWidth: 210,
    maxWidth: 310,
    minHeight: 300,
    maxHeight: () => window.innerHeight - 400,
    ratio,
  });

  // const previewWidth = 281;
  // const previewHeight = 610;

  const unityGameStyle = {
    width: previewWidth * 0.9,
    height: previewHeight * 0.9,
    marginTop: previewHeight * 0.02,
    marginLeft: previewWidth * 0.05,
    borderRadius: previewHeight * 0.07,
  };

  useEffect(() => {
    if (!isLoaded) return;
    sendMessage('ArObjectsContainer', 'SetNpcModel', npcModel ?? '');
  }, [npcModel, isLoaded, sendMessage]);

  useEffect(() => {
    if (!isLoaded) return;
    sendMessage('ArObjectsContainer', 'SetCollectableModel', collectableModel ?? '');
  }, [collectableModel, isLoaded, sendMessage])

  const handleCoinCollected = () => {
    logFirebaseEvent('webct_adventure_preview_npc_interacted');
  }

  const handleNpcInteracted = () => {
    logFirebaseEvent('webct_adventure_preview_coin_collected');
  }

  const handlePointerLockChange = () => {
    const element = document.pointerLockElement;
    setIsLocked(element !== null);
    logFirebaseEvent('webct_adventure_preview_phone_mode_changed');
      if (element === null) {
        sendMessage('LifecycleManager', 'EscapeKeyDownJs');
      }
  }

  useEffect(() => {
    if (!isLoaded) return;
    document.addEventListener('ArNpcInteracted', handleNpcInteracted);
    document.addEventListener('ArCoinCollected', handleCoinCollected);
    document.addEventListener('pointerlockchange', handlePointerLockChange);
    
    return () => {
      document.removeEventListener('ArNpcInteracted', handleNpcInteracted);
      document.removeEventListener('ArCoinCollected', handleCoinCollected);
      document.removeEventListener('pointerlockchange', handlePointerLockChange);
    };
  }, [isLoaded, sendMessage]);

  useEffect(() => {
    handleResize();
  }, [props.opened, isLoaded]);

  return !props.enabled ? null : (
    <div
      ref={divRef}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        className='unity-game-container'
        style={{
          width: previewWidth,
          height: previewHeight,
        }}
      >
        <img
          className='main-content-preview-background'
          src={previewPhoneImage}
          alt='phone background'
          style={{
            width: previewWidth,
            height: previewHeight * 0.94,
          }}
        />
        <Unity
          unityProvider={unityProvider}
          style={{
            // width: '100%',
            // height: 'auto',
            // aspectRatio: '281 / 610',
            backgroundColor: '#1b1b1b',
            display: isLoaded ? 'block' : 'none',
            ...unityGameStyle,
          }}
          className='unity-game-view'
        />
        <img
          className='unity-game-overlay-ui'
          src={previewOverlay}
          alt='preview overlay ui'
          style={{
            width: previewWidth,
            height: previewHeight * 0.94,
          }}
        />
        <img
          className='unity-game-overlay-text'
          src={previewOverlayText}
          alt='preview overlay hint for clicking to collect coins'
          style={{
            display: isLocked ? 'none' : 'block',
            width: previewWidth,
            height: previewHeight * 0.94,
          }}
        />
        {!isLoaded && <div className='unity-game-loader'><CircularProgress value={loadingProgression} /></div>}
      </div>
    </div>
  )
}

export default UnityGame;