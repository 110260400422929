import React, { useState, useEffect, ReactNode, useRef } from "react";
import { TextField, Button, InputAdornment, IconButton } from "@mui/material";
import MainPage from "./MainPage";
import ChatMessage from "./components/ChatMessage/ChatMessage";
import { ReactComponent as SendIcon } from "./assets/main-send-button.min.svg";
import "./main.css";
import AdventurePreview from "./components/AdventurePreview/AdventurePreview";
import { IAdventure } from "./interfaces/IAdventure";

import themeParkImage from "./assets/content/themepark.png";
import basketballImage from "./assets/content/basketball.png";
import universityImage from "./assets/content/university.png";
import { ReactComponent as ThemeParkIcon } from "./assets/content/theme-park-icon.svg";
import { ReactComponent as BasketballIcon } from "./assets/content/basketball-icon.svg";
import { ReactComponent as UniversityIcon } from "./assets/content/university-icon.svg";
import UnityGame from "./components/UnityGame/UnityGame";
import { IXSCollectibleConfig, IXSightMessage } from "./interfaces/IXSightMessage";
import PublishAdventureWindow from "./components/PublishAdventureWindow/PublishAdventureWindow";
// import { generateDynamicLink, generateShortLink } from "./firebase/dynamicLinks";
import { SubmitHandler, useForm } from "react-hook-form";
import { logFirebaseEvent } from "./firebase/firebase";
import UserPanel from "./components/UserPanel/UserPanel";
import { logInfo } from "./utils/common";

const userIcons = [
  '/images/userpic/icon1.svg',
  '/images/userpic/icon2.svg',
  '/images/userpic/icon3.svg',
  '/images/userpic/icon4.svg',
  '/images/userpic/icon5.svg',
];
const userIconId = Math.floor(Math.random() * userIcons.length);

const adventures: Array<IAdventure> = [
  {
    title: "Theme park",
    image: themeParkImage,
    id: 'theme_park',
    icon: <ThemeParkIcon />,
    previewUrl: "https://storage.googleapis.com/cloud_adventures_web_content/videos/themepark.mp4",
    prompt:
      "I would like to create an adventure, where players can discover theme park in AR and try to find and collect emojis, beat the boss, and unlock other parks",
  },
  {
    title: "Basketball",
    image: basketballImage,
    id: 'basketball',
    icon: <BasketballIcon />,
    previewUrl: "https://storage.googleapis.com/cloud_adventures_web_content/videos/basketball.mp4",
    prompt:
      "I want to create adventore on a basketball area, where players can collect balls and do something with it, bosses are redundant, there are no need for them",
  },
  {
    title: "University",
    image: universityImage,
    id: 'university',
    icon: <UniversityIcon />,
    previewUrl: "https://storage.googleapis.com/cloud_adventures_web_content/videos/university.mp4",
    prompt:
      "University events as is. Collect coins, score points, get something, do something and be nice, thats all folks",
  },
];

interface IChatFormInput {
  main: string;
}

interface ChatAppProps {
  userPanelOpened: boolean;
}

const ChatApp: React.FC<ChatAppProps> = (props) => {
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [messages, setMessages] = useState<IXSightMessage[]>([]);
  const [currentAdventure, setAdventure] = useState<IAdventure>(adventures[0]);
  const [currentPreviewAdventure, setCurrentPreviewAdventure] = useState<IAdventure | null>(adventures[0]);
  const [previewOpened, setPreviewOpened] = useState<boolean>(false);
  const mainChatListRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [allowScroll, setAllowScroll] = useState<boolean>(true);
  const [videosLoaded, setVideosLoaded] = useState<boolean>(false);
  const [chatStarted, setChatStarted] = useState<boolean>(true);
  const mainInputRef = useRef<HTMLInputElement>(null);
  const [currentCollectible, setCurrentCollectible] = useState<IXSCollectibleConfig | null>(null);
  const [publishOpened, setPublishOpened] = useState<boolean>(false);
  const [publishMessage, setPublishMessage] = useState<IXSightMessage | null>(null);
  const { register, handleSubmit, setValue, trigger: triggerValidation, formState: { isValid } } = useForm<IChatFormInput>({
    defaultValues: {
      main: adventures[0].prompt,
    }
  });
  const currentUserIcon = userIcons[userIconId];

  // const addPreviewMessage = () => {
  //   const msgs = messages;
  //   const result: IXSightMessage = {
  //     text: {
  //       type: "full",
  //       reporter: "output_message",
  //       message: "Congratulations! Your adventure is ready. You can now view it in the preview panel and start exploring the exciting world you've created.",
  //     },
  //     collectibleConfig: {
  //       arObjectId: "xcoin",
  //       collectibleTypeId: "xcoin",
  //       iconUrl: "https://storage.googleapis.com/cloud_adventures/uni-india/uni-india-coin-icon.svg",
  //       name: "Xcoin",
  //     },
  //   };
  //   msgs.push(result);
  //   setMessages(msgs);
  // }

  const displayPublish = (message: IXSightMessage) => {
    logInfo('publish adventure from message:', message);
    setPublishOpened(true);
  };

  const closePublishWindow = () => {
    setPublishOpened(false);
    setPublishMessage(null);
  }

  useEffect(() => {
    if (!currentCollectible) return;
    setPreviewOpened(true);
  }, [currentCollectible]);

  const handleScroll = () => {
    if (!mainChatListRef?.current) return;
    const { scrollTop, scrollHeight, clientHeight } = mainChatListRef.current;

    setScrollPosition(scrollTop);
    if (scrollPosition > scrollTop) {
      // scroll to top
      setAllowScroll(false);
    }
    if (scrollTop >= scrollHeight - clientHeight) {
      setAllowScroll(true);
    }
  };

  useEffect(() => {
    setValue('main', currentAdventure.prompt);
    triggerValidation();
  }, [currentAdventure]);

  useEffect(() => {
    if (!mainChatListRef?.current) return;
    if (mainChatListRef?.current && allowScroll)
      mainChatListRef.current.scrollTo({
        left: 0,
        top: mainChatListRef.current.scrollHeight,
      });
  }, [mainChatListRef, messages, allowScroll]);

  useEffect(() => {
    if (!chatStarted) return;
    // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const webSocket = new WebSocket("ws://127.0.0.1:8000/chat/PST");
    //TODO: get timzone of the user
    webSocket.onmessage = (message) => {
      const message_curr: IXSightMessage = JSON.parse(message.data);
      if (message_curr.text.reporter === "output_message") {
        setMessages((messages_prev) => {
          // If messages_prev is empty, simply add the new message.
          if (messages_prev.length === 0) {
            return [message_curr];
          }

          // Get the last message from the previous state.
          const message_last = messages_prev[messages_prev.length - 1];

          if (
            message_last.text.type === "question" ||
            message_last.text.type === "full"
          ) {
            if (message_last.text.type === 'full') {
              if (message_last.collectibleConfig) logFirebaseEvent('webct_ai_replied_with_adventure');
              else logFirebaseEvent('webct_ai_replied');
            }
            return [...messages_prev.slice(), message_curr];
          }

          // If the last message is 'full', append the new message.
          if (message_curr.text.type === "full") {
            return [...messages_prev.slice(0, -1), message_curr];
          }

          const finalMessage: IXSightMessage = {
            ...message_last,
            text: {
              ...message_last.text,
              message: message_last.text.message + message_curr.text.message,
            }
          }

          // If the last message is not 'full', concatenate it with the new message.
          return [
            ...messages_prev.slice(0, -1), // all messages except the last
            finalMessage,
          ];
        });
      }
    };

    setWs(webSocket);

    // Clean up on unmount
    return () => webSocket.close();
  }, [chatStarted]);

  const closePreviewPanel = () => {
    setPreviewOpened(false);
    setCurrentPreviewAdventure(null);
    logFirebaseEvent('webct_adventure_preview_closed');
  };

  const openPreviewPanel = (model: IXSCollectibleConfig | null) => {
    setCurrentCollectible(model);
    setPreviewOpened(true);
    logFirebaseEvent('webct_adventure_preview_shown');
  }

  const clearInput = () => {
    if (mainInputRef.current) {
      mainInputRef.current.value = '';
    }
    setValue('main', '');
    triggerValidation();
  }

  const sendMessage = (input: string): void => {
    if (!chatStarted) {
      setChatStarted(true);
    }
    if (ws) {
      if (!input) return;
      ws.send(input);

      setMessages((messages_prev) => [
        ...messages_prev,
        { text: 
          {
            type: "question",
            reporter: "user",
            message: input,
          }
        },
      ]);

      clearInput();
    }
  };

  const renderSendButton = (): ReactNode | null => {
    const createButton = <Button
        disabled={!isValid}
        className="main-send-button"
        type="submit"
        style={{ marginLeft: 16 }}
      >
        Create
        <SendIcon />
      </Button>;
    const sendButton = <IconButton
      disabled={!isValid}
      type="submit"
      className="main-send-button main-send-button-icon"
      style={{ marginLeft: 16 }}
      >
        <SendIcon />
      </IconButton>
    return chatStarted ? sendButton : createButton;
  };

  // const [coin, setCoin] = useState<string>("");
  // const toggleCoin = () => {
  //   setCoin(coin === "xcoin" ? "basketBall" : "xcoin");
  // };
  // const [model, setModel] = useState<string>("");
  // const toggleModel = () => {
  //   setModel(model === "man" ? "shaqSportsman" : "man");
  // };

  // const displayBengalTiger = () => {
  //   setModel("BengalTiger");
  // };

  // const generateLink = () => {
  //   generateShortLink('adv1', 'usr2');
  // };

  const onSubmit: SubmitHandler<IChatFormInput> = (data) => {
    sendMessage(data.main);
    clearInput();
  };

  return (
    <div className="t-main-screen">
      <PublishAdventureWindow message={publishMessage} isOpen={publishOpened} toggleModal={closePublishWindow} />
      <UserPanel opened={props.userPanelOpened} />
      <div className="t-ChatApp">
        {/* <button type="button" onClick={() => addPreviewMessage()}>
          add message with preview
        </button> */}
        <div className="t-main-chat">
          {/* <button style={{ width: 200 }} type="button" onClick={generateLink}>generate link</button> */}
          <div
            className="main-chat-list"
            ref={mainChatListRef}
            onScroll={handleScroll}
          >
            <MainPage
              adventures={adventures}
              onSelectAdventure={setAdventure}
              onHoverAdventure={setCurrentPreviewAdventure}
              onUnhoverAdventure={() => setCurrentPreviewAdventure(null)}
              onVideosLoaded={setVideosLoaded}
            />
            {messages.map((msg, index) => {
              const isUser = msg.text.reporter === "user";

              return <ChatMessage
                key={`chat-message-${index}`}
                isUser={isUser}
                avatarUrl={isUser ? currentUserIcon : undefined}
                isPreview={!!msg.collectibleConfig}
                isPublish={!!msg.collectibleConfig}
                onPreview={() => openPreviewPanel(msg.collectibleConfig || null)}
                onPublish={() => displayPublish(msg)}
                isLast={index === messages.length - 1}
                >
                  {msg.text.message}
                </ChatMessage>;
            })}
          </div>
          
        </div>
        <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ width: '100%'}}
            className="t-main-input-form"
          >
            <TextField
              placeholder="Start typing here..."
              variant="outlined"
              className="t-main-input"
              // inputRef={mainInputRef}
              multiline={true}
              {...register('main', { required: 'Please enter message' })}
              fullWidth
              minRows={1}
              maxRows={8}
              // value={input}
              // onChange={(e: any) => setInput(e.target.value)}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === "Enter") {
                  handleSubmit(onSubmit)();
                  // sendMessage();
                  clearInput();
                }
              }}
              onKeyUp={(e: React.KeyboardEvent) => {
                if (e.key === "Enter") {
                  clearInput();
                }
              }}
              InputProps={{
                endAdornment: (
                  <div style={{ height: '100%', maxHeight: '100%', minHeight: '100%' }}>
                    <InputAdornment
                      className="main-send-button-container"
                      position="end"
                    >
                      {renderSendButton()}
                    </InputAdornment>
                  </div>
                ),
              }}
            />
          </form>
      </div>
      <div className={`right-panel ${previewOpened ? "opened" : "closed"}`}>
        <AdventurePreview
          info={currentPreviewAdventure || currentAdventure}
          onClose={closePreviewPanel}
        >
          <UnityGame
            collectableModel={currentCollectible?.collectibleTypeId}
            npcModel={currentCollectible?.arObjectId}
            enabled={videosLoaded}
            opened={previewOpened}
          />
          {/* <button onClick={toggleCoin}>coin/ball</button>
          <button onClick={toggleModel}>model</button>
          <button onClick={displayBengalTiger}>bengal tiger</button> */}
        </AdventurePreview>
      </div>
    </div>
  );
};

export default ChatApp;
