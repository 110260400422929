import React, { useCallback, useEffect } from "react";

interface IDivResizeProps {
  setWidth: (width: number) => void;
  setHeight: (height: number) => void;
  divRef: React.RefObject<HTMLDivElement>;
  width: number;
  minWidth: number;
  maxWidth: number;
  minHeight: number;
  maxHeight: number | (() => number);
  ratio: number;
}

interface IDivResizeResult {
  handleResize: () => void;
}

export const useDivResize = (props: IDivResizeProps): IDivResizeResult => {
  const { setWidth, setHeight, divRef, minHeight, maxHeight, ratio, width } = props;

  const handleResize = useCallback(() => {
    const panel: HTMLElement | null = divRef?.current;
    if (!panel) return;
    let w = panel.clientWidth;
    let h = w * ratio;
    const maxH: number = (maxHeight instanceof Function) ? maxHeight() : maxHeight;
    if (h > maxH) {
      h = maxH > minHeight ? maxH : minHeight;
      w = h / ratio;
    }
    if (w === width) return;
    setWidth(parseInt(w.toString()));
    setHeight(parseInt(h.toString()));
  }, [setWidth, setHeight, width]);

  const observerCallback: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
    window.requestAnimationFrame((): void | undefined => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      handleResize();
    });
  };

  // Attach resize event listener when the component mounts
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    
    const observer = new ResizeObserver(observerCallback);

    if (divRef.current) observer.observe(divRef.current);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, [handleResize]);

  useEffect(() => {
    if (divRef) handleResize();
  }, [handleResize, divRef]);

  return { handleResize };
}