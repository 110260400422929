import React, { useEffect, useState } from 'react';
import { DialogActions, Button } from '@mui/material';
import './CookiesWindow.css';
import cookieImg from '../../assets/cookie.svg';

const CookiesWindow = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookiesConsent');
    if (consent === null || consent === undefined || consent === '') {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesConsent', 'accepted');
    setOpen(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookiesConsent', 'declined');
    setOpen(false);
  };

  if (!open) return null;

  return (
    <div className='cookies-window'>
      <div className='cookies-window-content'>
        <img src={cookieImg} />
        <span>We use cookies to enhance your best experience on our website. By clicking &quot;Accept&quot;, you agree to the use of all cookies. 
        <br />
        <a className='cookies-window-url' href='https://www.xsight.online/legal-pages'>More information</a>
        </span>
      </div>
        
      <DialogActions>
        <Button onClick={handleDecline} variant="text" className='cookies-window-button'>
          Decline
        </Button>
        <Button onClick={handleAccept} className='cookies-window-button cookies-window-accept'>
          Accept
        </Button>
      </DialogActions>
    </div>
  );
};

export default CookiesWindow;