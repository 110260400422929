import * as amplitude from '@amplitude/analytics-browser';

const AMPLITUDE_API_KEY = 'e97b0d827363d0f8d6b85bc4fa313914';

export const initAmplitude = () => {
  amplitude.init(AMPLITUDE_API_KEY, {
    defaultTracking: true,
    appVersion: '1.0.0',
  });
};
