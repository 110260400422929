import React, { useEffect } from 'react';
import './PublishAdventureWindow.css';
import ModalWindow from '../ModalWindow/ModalWindow';
import { ReactComponent as SuccessIcon } from "../../assets/icons/success.svg";
import { ReactComponent as CopyLinkIcon } from "../../assets/icons/copy-link.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook.svg";
import { ReactComponent as XTwitterIcon } from "../../assets/icons/x-twitter.svg";
import { ReactComponent as RedditIcon } from "../../assets/icons/reddit.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/icons/linkedin.svg";
import PublishAdventureButton from './PublishAdventureIcon';
import { IXSightMessage } from '../../interfaces/IXSightMessage';
import { logFirebaseEvent } from '../../firebase/firebase';

interface PublishAdventureWindowProps {
  isOpen: boolean;
  message: IXSightMessage | null;
  toggleModal: (value: boolean) => void;
}

interface LinkInfo {
  icon: any;
  title: string;
  url: string;
}

const links: Array<LinkInfo> = [
  { icon: <CopyLinkIcon />, title: 'Copy Link', url: 'localhost:3003/link' },
  { icon: <InstagramIcon />, title: 'Instagram', url: 'localhost:3003/instagram' },
  { icon: <FacebookIcon />, title: 'Facebook', url: 'localhost:3003/facebook' },
  { icon: <XTwitterIcon />, title: 'X (Twitter)', url: 'localhost:3003/xtwitter' },
  { icon: <RedditIcon />, title: 'Reddit', url: 'localhost:3003/reddit' },
  { icon: <LinkedinIcon />, title: 'LinkedIn', url: 'localhost:3003/linkedin' },
]

const PublishAdventureWindow: React.FC<PublishAdventureWindowProps> = (props) => {

  useEffect(() => {
    logFirebaseEvent(props.isOpen ? 'webct_adventure_published_dialog_shown' : 'webct_adventure_published_dialog_closed');
  }, [props.isOpen]);

  return <ModalWindow
    isOpen={props.isOpen}
    styles={{ 
      width: '100%',
      height: '100%',
      maxWidth: 900,
      maxHeight: 500,
      minWidth: 400,
      minHeight: 200,
    }}
    toggleModal={props.toggleModal}
    withClose
  >
    <div className='publish-adventure-content'>
      <SuccessIcon />
      <span className='publish-adventure-header'>Adventure Successfully Published!</span>
      <div className='social-links-block'>
        {links.map((link) => <PublishAdventureButton key={link.url} icon={link.icon} title={link.title} url={link.url} />)}
      </div>
    </div>
  </ModalWindow>
}

export default PublishAdventureWindow;
