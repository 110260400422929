import React, { useEffect, useRef } from 'react';

interface PreviewVideoPlayerProps {
  videoUrls: string[];
  currentUrl: string;
  width: number;
  height: number;
  onVideoLoaded: (value: boolean) => void;
}

const PreviewVideoPlayer: React.FC<PreviewVideoPlayerProps> = ({ videoUrls, currentUrl, width: previewWidth, height: previewHeight, onVideoLoaded }) => {

  const videoRefs = useRef<Record<string, HTMLVideoElement | null>>({});

  const videoParams = {
    muted: true,
    className: 'main-content-preview-video',
    style: {
      width: previewWidth * 0.9,
      height: previewHeight * 0.9,
      marginTop: previewHeight * 0.02,
      marginLeft: previewWidth * 0.05,
      borderRadius: previewHeight * 0.07,
    },
    autoPlay: true,
    loop: true,
  }
  
  // Pause all videos and play the one with the currentUrl when it changes
  useEffect(() => {
    videoUrls.forEach((url) => {
      const videoRef = videoRefs.current[url];

      if (videoRef) {
        if (url === currentUrl) {
          videoRef.play();
        } else {
          videoRef.pause();
        }
      }
    });
  }, [currentUrl, videoUrls]);

  // Check if all videos are loaded
  const areVideosLoaded = videoUrls.every((url) => {
    const videoRef = videoRefs.current[url];
    return videoRef && videoRef.readyState >= 4; // readyState 4 means the video is loaded
  });

  // Call onVideoLoaded(true) when all videos are loaded
  useEffect(() => {
    if (areVideosLoaded) {
      onVideoLoaded(true);
    }
  }, [areVideosLoaded, onVideoLoaded]);

  return (
    <div key={videoUrls.join('-')}>
      {
        videoUrls.map((url) => <video
          key={url}
          ref={(el) => (videoRefs.current[url] = el)}
          {...videoParams}
          src={url}
          muted
          autoPlay
          playsInline
          style={{
            ...videoParams?.style,
            opacity: (url === currentUrl) ? 1 : 0,
            transition: 'opacity 0.25s ease-in-out',
          }}
          />)
      }
    </div>
  );
};

export default PreviewVideoPlayer;